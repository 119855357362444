/**
 * 面授创建API
 */
import { fetch, post, del, postForm } from 'api/base/axios'

const URL = {
  saveUrl: '/curriculum/cur/save/',
  uploadPicUrl: '/curriculum/cur/uploadPic/',
  getUrl: '/curriculum/cur/get/',
  checkNameUrl: '/curriculum/cur/check_name_exist/',
  listFaceUrl: '/curriculum/face_teaching/list/',
  listVideoUrl: '/curriculum/video/list/',
  listAuthUrl: '/curriculum/authentication/list/',
  listLiveUrl: '/curriculum/live/list/',
  listCateUrl: '/curriculum/cate/list/',
  listTeacherUrl: '/teacher/list/',
  like: '/like/',
  unlike: '/unlike/',
  is_favor_like: '/get_favor_like/',
  user_favor: '/get_favors/',
  invalid_favor: '/get_invalid_favors/',
  listCourseWare: '/curriculum/cur/list_course_ware/',
  courseReportNum: '/curriculum/cur/course_report_num/',
  listExperimentUrl: '/curriculum/experiment/list/',
  startExptUrl: '/curriculum/experiment/create/',
  exptInfoUrl: '/curriculum/experiment/expt_info/',
}

/**
 * 查询用户
 * @param data
 * @returns {Promise}
 */
export function save (data) {
  return post(URL.saveUrl, data)
}

export function uploadPic (data) {
  return post(URL.uploadPicUrl, data)
}

export function get (uuid, data={}) {
  return fetch(URL.getUrl + uuid + '/', data)
}

export function listFace(data){
  return fetch(URL.listFaceUrl, data)
}

export function listAuth(data) {
  return fetch(URL.listAuthUrl, data)
}

export function listVideo(data) {
  return post(URL.listVideoUrl, data)
}

export function listLive(data) {
  return post(URL.listLiveUrl, data)
}

export function listCate(data) {
  return fetch(URL.listCateUrl, data)
}

export function listExperiment(data) {
  return post(URL.listExperimentUrl, data)
}
export function startExpt(data) {
  return post(URL.startExptUrl, data)
}

export function exptInfo(uuid,data) {
  return fetch(URL.exptInfoUrl + uuid + '/', data)
}

export function listTeacher(data) {
  return fetch(URL.listTeacherUrl, data)
}

export function checkNameExist (uuid, value) {  //金额校验
  return fetch(URL.checkNameUrl, {'uuid': uuid, 'name': value})
}

export function listCourseWare(data) {
  return fetch(URL.listCourseWare, data)
}

export function getCurReportNum(data) {
  return fetch(URL.courseReportNum, data)
}

export function prepareChapter(chapter_list) {
  var chapter_data = []
  //0：视频；1：直播；2：面授；3：认证;6 :实验
  for(var i=0; i<chapter_list.length; i++){
    var chatper = {
      serial: i,
      title: chapter_list[i].title,
      content: [],
      introduction: chapter_list[i].introduction
    }
    for(var j=0; j<chapter_list[i].chapter.length; j++){
      var type = chapter_list[i].chapter[j].type
      if(type == 0){
        chatper.content.push({
          serial: j,
          video_uuid: chapter_list[i].chapter[j].uuid
        })
      }
      else if(type == 1){
        chatper.content.push({
          serial: j,
          live_uuid: chapter_list[i].chapter[j].uuid
        })
      }
      else if(type == 2){
        chatper.content.push({
          serial: j,
          face_uuid: chapter_list[i].chapter[j].uuid
        })
      }
      else if(type == 3){
        chatper.content.push({
          serial: j,
          authentication_uuid: chapter_list[i].chapter[j].uuid
        })
      }
      else if(type == 6){
        chatper.content.push({
          serial: j,
          experiment_uuid: chapter_list[i].chapter[j].uuid
        })
      }
    }
    chapter_data.push(chatper)
  }
  return chapter_data
}

export function initData(data){
  console.log(data)
  var form = {
    name: data.name,
    desc: data.description,
    people_num: data.people_num,
    strike_price: data.mark_price,
    discount_price: data.final_price,
    level: data.difficulty.toString(),
    img_cover: [{'url': data.cover_image}],
    tag_name: data.cover_image_label,
    course_intro: data.introduce,
    syllabus: data.outline,
    try_num: data.video_trial_num,
    try_time: data.video_trial_time,
    recorder: data.weight,
    status: data.state.toString(),
    sort: [],
    teachers_list: data.teachers,
    chapter_list: [],
    nowChapterIndex: data.cur_chapter.len,
    publish_at: data.state == 2 ? data.publish_at : ''
  }
  for(var i=0; i<data.categories.length; i++){
    form.sort.push([data.categories[i].category_lv1.id, data.categories[i].id])
  }
  for(i=0; i<data.cur_chapter.length; i++){
    let chapter = {
      id: i,
      title: data.cur_chapter[i].title,
      isEdit: false,
      isHide: false,
      chapter: [],
      introduction: data.cur_chapter[i].introduction
    }
    for(var j=0; j<data.cur_chapter[i].chapter_content.length; j++){
      let course = data.cur_chapter[i].chapter_content[j]
      if(course.video_uuid){
        chapter.chapter.push({
          uuid: course.video.uuid,
          title: course.video.name,
          type: 0,
          time: course.video.duration
        })
      }
      else if(course.live_uuid){
        chapter.chapter.push({
          uuid: course.live.uuid,
          title: course.live.name,
          type: 1,
          time: dayjs(course.live.start_at).format('YYYY-MM-DD HH:mm:ss')
        })
      }
      else if(course.face_uuid){
        chapter.chapter.push({
          uuid: course.face_teaching.uuid,
          title: course.face_teaching.name,
          type: 2,
          date: course.face_teaching.start_at,
          position_str: course.face_teaching.position_str,
        })
      }
      else if(course.authentication_uuid){
        chapter.chapter.push({
          uuid: course.authentication.uuid,
          title: course.authentication.name,
          type: 3,
          expires: course.authentication.avaliable_days,
          code: course.authentication.avaliable_count
        })
      }
      else if(course.experiment_uuid){
        chapter.chapter.push({
          uuid: course.experiment.course_uuid,
          title: course.experiment.course_name,
          duration: course.experiment.duration,
          type: 6,
        })
      }
    }
    form.chapter_list.push(chapter)
  }
  return form
}

export function like (data) {
  return post(URL.like, data)
}

export function unlike (data) {
  return post(URL.unlike, data)
}

export function get_favor_like (target_uuid) {
  return fetch(URL.is_favor_like + target_uuid + '/')
}

export function get_user_favors (data) {
  return post(URL.user_favor, data)
}

export function get_invalid_favors () {
  return fetch(URL.invalid_favor)
}
