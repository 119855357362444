<template>
  <!--地图容器-->
  <div id="map" class="map"></div>
</template>

<script>
import { baiduMap } from 'utils/baiduMap'
export default {
  name: '',
  data() {
    return {
      ak: 'OwPN0Y2i7K29hNoQaHGIDGacw8noc4NA'
    }
  },
  mounted() {
    this.$nextTick(()=> {
      let _this = this;
      baiduMap(_this.ak).then((err) => {
        const map = new BMap.Map("map");    // 创建Map实例
        const point = new BMap.Point(118.824826,31.877121);
        const marker = new BMap.Marker(point);        // 创建标注
        map.centerAndZoom(new BMap.Point(118.824826,31.877121), 16);  // 初始化地图,设置中心点坐标和地图级别
        map.addOverlay(marker);                     // 将标注添加到地图中
        map.setCurrentCity("南京");          // 设置地图显示的城市 此项是必须设置的
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      })
    })
  },
}

</script>
<style lang="scss" scoped>
.map {
  height: 250px;
}

</style>
